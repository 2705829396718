
@media screen and (width < 769px) {
  a.main_logo {
    display: none !important;
  }
  div.container .row h1.title {
    margin-top: 0 !important;
  }
  .container .row:first-of-type div.panel-default:first-of-type {
    margin-top: 0 !important;
  }
  .btn-back {
    top: 7px !important;
  }
}
