footer {
  display: block;
  margin-top: 1em;
  border: solid #dce0e0;
  text-align: center;
  p {
    margin: 0;
    padding: 15px;
    font-size: 15px;
    font-weight: bold;
  }
}
