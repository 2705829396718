$bgDefault: #9b59b6;
$bgHighlight: #8e44ad;
$colDefault: #ecf0f1;
$colHover: #ecdbff;
$colHighlight: #eaf0ce;

main {
  flex: 1 1 auto;
}

#headerLogo {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.printOnly {
  display: none;
}

label.required::after {
  content: ' (required)';
}

.btn-back {
  top: 25px !important;
  left: 24px;
}

@media print {
  .noprint {
    display: none !important;
  }
  .printOnly {
    display: block !important;
  }

  // Stop buttons showing href inside button when printing
  a[href]::after {
    content: none !important;
    visibility: hidden !important;
  }
}

.form-inline {
  .pcb_num_field {
    width: 80px;
    font-weight: 700;
  }
}

.form-horizontal .control-label {
  text-align: left;
}

.station-report-item:nth-child(even) {
  background-color: #eeeeee;
}

.navbar-fixed-bottom .btn-group {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 100px;
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 100px;
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    bottom: 100px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
@keyframes fadeout {
  from {
    bottom: 100px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.show {
  display: block;
}

.login {
  position: relative;
  top: 25px;
  margin-bottom: 15px;
  padding: 10px;
  border: solid #dce0e0;
  border-radius: 5px;
}

.login-header {
  position: relative;
  left: 2px;
  padding-bottom: 10px;
}

.edit_ply_form {
  width: 100%;
}

.edit_colour {
  display: grid;
  padding: 1em;
  grid-template-columns: 1fr min-content min-content;
  grid-gap: 1em;
  white-space: nowrap;
  & .heading {
    font-weight: 700;
  }
  & .ply_name {
    font-size: 12px;
    font-weight: 700;
  }
  & div input {
    width: 5em;
  }
  & .btn {
    grid-column-start: 3;
  }
}
