@use "sass:color";

%badge {
  padding: 1px 9px 2px;
  font-weight: bold;
  white-space: nowrap;
}

.badge-error {
  @extend %badge;
  background-color: #b94a48;
  color: #ffffff;
  &:hover {
    background-color: color.adjust(#b94a48, $lightness: -5%);
  }
}

.badge-warning {
  @extend %badge;
  background-color: #f89406;
  color: #ffffff;
  &:hover {
    background-color: color.adjust(#f89406, $lightness: -5%);
  }
}
