.order_header {
  margin-left: 0;
}

.line-item-container {
  margin: 10px 0;
  padding: 20px;
  //background-color: #f9f9f9;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
}

.add-line-item-button,
.add-size-button,
.remove-line-item-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  text-decoration: none;
}

.remove-line-item-btn {
  background-color: #dc3545;
}

.text-area-style {
  padding: 10px;
  border-radius: 4px;
}

.form-check-input {
  margin-top: 0.3rem;
}
