body {
  display: flex;
  min-height: 100vh;
  flex-flow: column;
}

pre {
  padding: 10px;
  font-size: 11px;
  background-color: #eeeeee;
}

div {
  &.field {
    margin-bottom: 10px;
  }
}

.error_explanation {
  width: 450px;
  margin-bottom: 20px;
  padding: 7px 7px 0;
  background-color: #f0f0f0;
  border: 2px solid red;
  h2 {
    margin: -7px -7px 0;
    padding: 5px 5px 5px 15px;
    font-size: 12px;
    font-weight: bold;
    background-color: #cc0000;
    color: #ffffff;
    text-align: left;
  }
  ul li {
    font-size: 12px;
    list-style: square;
  }
}

label {
  display: block;
}
