.digg_pagination {
  display: flex;
  margin-bottom: 1em;
  grid-gap: 0.5em 0;
  flex-wrap: wrap;
  a,
  span,
  em {
    border-radius: 0;
    flex: 1 1 auto;
    @extend %btn !optional;
    @extend %btn-primary !optional;
  }
  .current {
    @extend %active !optional;
  }
  .page_info {
    margin-bottom: 0.3em;
    padding: 0.4em 0.6em;
    background: #e0e0e0;
    flex: 0 0 100%;
    text-align: center;
    b {
      padding: 0.1em 0.25em;
      background: #e0e0e0;
    }
  }
  .previous_page {
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
  }
  .next_page {
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}
