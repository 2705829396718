html {
  scroll-behavior: smooth;
}

$size: 50px;
$thickness: 5px;
$angle: 40deg;
$angleHover: 30deg;
$angleActive: 25deg;
@mixin arrowTransform( $angle, $x: 0, $y: 0 ) {
  i:first-child {
    transform: translate($x, $y) rotate($angle);
  }
  i:last-child {
    transform: translate($x, -$y) rotate(-$angle);
  }
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  outline: 0;
}

.paginate {
  position: absolute;
  top: 50%;
  width: $size;
  height: $size;
  margin: -20px 10px 10px;
  cursor: pointer;
  filter: drop-shadow(0 2px 0 rgb(0 0 0 / 20%));
  transform: translate3d(0, 0, 0);
  i {
    position: absolute;
    top: 40%;
    left: 0;
    width: $size;
    height: $thickness;
    background: #ffffff;
    border-radius: calc($thickness / 2);
    transition: all 0.15s ease;
  }
  &.left {
    right: 68%;
    i {
      transform-origin: 0 50%;
    }
    @include arrowTransform($angle, 0, -1px);
    &:hover {
      @include arrowTransform($angleHover, 0, -1px);
    }
    &:active {
      @include arrowTransform($angleActive, 1px, -1px);
    }
    &[data-state="disabled"] {
      @include arrowTransform(0deg, -5px, 0);
      &:hover {
        @include arrowTransform(0deg, -5px, 0);
      }
    }
  }
  &.center {
    top: 50%;
    right: 50%;
    width: fit-content;
    text-align: center;
    transform: translate(50%, -50%);
    white-space: nowrap;
    h5 {
      margin-top: 1.67em;
    }
  }
  &.right {
    left: 68%;
    i {
      transform-origin: 100% 50%;
    }
    @include arrowTransform($angle, 0, 1px);
    &:hover {
      @include arrowTransform($angleHover, 0, 1px);
    }
    &:active {
      @include arrowTransform($angleActive, 1px, 1px);
    }
    &[data-state="disabled"] {
      @include arrowTransform(0deg, 5px, 0);
      &:hover {
        @include arrowTransform(0deg, 5px, 0);
      }
    }
  }
  &[data-state="disabled"] {
    cursor: default;
    opacity: 0.3;
  }
}

.btn-important {
  font-weight: 700;
}

.panel {
  & .heading {
    font-weight: 700;
    a {
      color: #000000;
    }
  }
}

.dropdown-white .edit_colour {
  background: #ffffff;
}

@media print {
  .station-report-item {
    display: block !important;
  }
}
