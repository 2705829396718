@import "bootstrap";
@import "layout";
@import "ply_colours";
@import "digg-pagination";
@import "badges";
@import "global";
@import "scaffolds";
@import "stock_management";
@import "customer_orders";
@import "mobile";
@import "colourways";
@import "gansey_orders";
@import "cost_sheet";

h1 {
  font-size: 22px;
}

h1, h2, h3 {
  text-align: center;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

a.main_logo {
  display: contents;
}

div.container .row.title_container {
  position: relative;
}

div.container .row h1.title {
  margin: 18px 15px;
  padding: 10px 0;
  background-color: #f5f5f5;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  color: #333333;
  &.has_toolbar {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.panel.panel-default.toolbar {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media screen {
  div.qr {
    margin-bottom: 18px;
  }
  div.qr img {
    max-width: 150px;
  }
}
@media print {
  div.qr {
    margin-top: 5 px;
    margin-bottom: 5 px;
  }
  div.qr img {
    width: 12em;
    height: 12em;
  }
}

.container .row:first-of-type div.panel-default:first-of-type {
  margin-top: 18px;
}

.container .row:first-of-type div.panel-heading:first-of-type h2 {
  margin: 0;
  padding: 0;
  font-size: 22px;
}

.container .row:first-of-type div.panel-heading {
  margin: 0;
  padding: 10px 15px;
}

.panel-heading {
  margin: 0;
  padding: 10;
}

.panel-heading h4 {
  margin: 0;
  font-size: 22px;
}

.priority {
  font-weight: 700;
  color: #ff0000;
}

.priority.large {
  font-size: 75px;
}

#batch-form .select2-container {
  width: 100% !important;
}

.paginate_container {
  height: 7rem;
  font-size: 25px;
}

.navbar {
  border-radius: 0 !important;
}

.navbar,
.footer {
  opacity: 0.95;
}

.navbar-sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.footer {
  background-color: $brand-primary;
  color: white;
}

footer.footer_with_pagination {
  margin-bottom: 7rem;
}

.select2-dropdown.ply_type ul {
  max-height: 500px !important;
}
