.note_container {
  padding-right: 0;
  padding-left: 0;
}

.note_form {
  padding: 0;
  align-items: flex-end;
  flex-flow: column;
  & textarea {
    width: 100%;
    margin-bottom: 1em;
    resize: vertical;
  }
}

.panel-heading .form-inline input {
  margin-top: 5px;
}

.panel-heading .button.float-right {
  margin-right: 4px !important;
}
