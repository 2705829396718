$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-size-base: 13px;
$font-family-base: verdana, arial, helvetica, sans-serif;
$brand-primary: #9b59b6;
$brand-text-primary: #ffffff;

$navbar-default-bg: $brand-primary;
$navbar-default-color: $brand-text-primary;
$navbar-default-link-color: $brand-text-primary;
$navbar-default-link-active-color: $brand-text-primary;
$navbar-default-link-hover-bg: $brand-primary;
$navbar-default-toggle-icon-bar-bg: $brand-text-primary;

$dropdown-bg: $brand-primary;
$dropdown-link-color: $brand-text-primary;

$list-group-hover-bg: #e0e0e0;

@import "~bootstrap-sass";

/* stylelint-disable scss/at-extend-no-missing-placeholder */
.field_with_errors {
  @extend .has-error;
}

// Hack until Devise pages are styled up
.login {
  @extend .container;
}
/* stylelint-enable scss/at-extend-no-missing-placeholder */

.card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.card {
  display: flex;
  position: relative;
  min-width: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-clip: border-box;
  background-color: #ffffff;
  border: 1px solid rgb(0 0 0 / 12.5%);
  border-radius: 0.25rem;
  flex-direction: column;
  word-wrap: break-word;
}

.card-header {
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  background-color: rgb(0 0 0 / 3%);
  border-bottom: 1px solid rgb(0 0 0 / 12.5%);
  &:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
  &:last-child {
    border-bottom: 0;
  }
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgb(0 0 0 / 3%);
  border-top: 1px solid rgb(0 0 0 / 12.5%);
  &:first-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
  }
}

.form-horizontal.compact .form-group {
  margin-bottom: 5px;
}

@media print {
  .print-visible {
    display: block !important;
  }
}
