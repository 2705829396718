.other-colour-input {
  margin-left: -120px;
}

@media (width <= 820px) {
  .select2-container {
    width: 100% !important;
    margin-right: 0 !important;
  }
  .other-colour-input {
    display: block !important;
    width: 100% !important;
    margin-left: 0 !important;
  }
  #standard-carriers .form-group, #reversed-carriers .form-group {
    margin-right: 0;
    margin-left: 0;
    padding-right: 15px;
    padding-left: 15px;
  }
}
