@import "bootstrap";
@import "select2/dist/css/select2.css";
@import "select2-bootstrap-theme/dist/select2-bootstrap.css";

table.orderDetails {
  margin-bottom: 20px;
}

table.orderDetails tbody tr {
  padding-right: 10px;
  font-weight: 700;
  text-align: left;
}

.pickedBox {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid black;
}

.remove_button {
  float: right;
}

.select2 {
  width: inherit !important;
  min-width: 200px;
}

.select2-results__group {
  background-color: $brand-primary;
  color: $brand-text-primary !important;
}

.card {
  .select2 {
    width: 100% !important;
  }
  .order_item_form {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
  }
  .item-footer.buttons {
    padding-top: 15px;
    padding-right: 15px;
  }
  .card-toggle-label-container {
    display: block;
    margin-right: 15px;
    padding-bottom: 5px;
  }
  .card-toggle-label-container button {
    margin-left: 10px;
  }
  .card-toggle-label-container label {
    display: inline-block;
  }
  .card-header-toggle.collapsed button.collapse,
  .card-header-toggle:not(.collapsed) button.expand {
    display: none;
  }
  .card-header-toggle.collapsed button.expand {
    display: unset;
  }
  .card-header-toggle:not(.collapsed) button.collapse {
    display: unset;
  }
}

@media screen and (width <= 650px) {
  .select2 {
    min-width: 150px;
  }
}
@media print {
  table.packing-note {
    font-size: 10px;
  }
  table.packing-note tr > *:nth-child(4) {
    display: none;
  }
}
